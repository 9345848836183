import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const About = () => (
  <Layout>
    <SEO title="About" />
    <h1>About this site</h1>
	<p>This is the score page for a certain Facebook puzzle group where I post puzzles (mostly) from Raymond Smullyan's "To mock and mockingbird and other logic puzzles" book.</p>
    <Link to="/scores/">Go back to the scores</Link>
  </Layout>
)

export default About
